<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="6">
          <el-form-item label="课程id">
            <el-input v-model="courseId" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="onQueryTicket"
            >查询可用的</el-button
          >
          <el-button @click="onQueryTicket">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="6">
          <el-form-item label="课程id">
            <el-input v-model="usedCourseId" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="onQueryUsedTicket"
            >查询已使用的</el-button
          >
          <el-button @click="onQueryUsedTicket">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="6">
          <el-form-item label="phone">
            <el-input v-model="phone" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="onQueryUsedByPhone"
            >查询已使用的</el-button
          >
          <el-button @click="onQueryUsedByPhone">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="ticketList" highlight-current-row style="width: 100%">
      <el-table-column label="优惠券" width="400">
        <template slot-scope="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="courseId" label="courseId"></el-table-column>
      <el-table-column prop="userId" label="userId"></el-table-column>
      <el-table-column prop="userPhone" label="phone"></el-table-column>

      <el-table-column
        prop="createTime"
        :formatter="formatTime"
        label="创建时间"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        :formatter="formatTime"
        label="修改时间"
      ></el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar" v-if="pageType == 1 || pageType == 2">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
import { ticketApi } from "@/api/ticketApi";
import { epochToDateString } from "@/util/TimeUtil";
import { alertMessage } from "@/util/alertMessageUtil";

export default {
  data() {
    return {
      pageType: 1,
      currentPage: 1,
      totalPage: 1,
      courseId: 0,
      usedCourseId: 0,
      phone: 0,
      ticketList: [],
    };
  },
  mounted() {},
  methods: {
    formatTime: function (row, column) {
      return epochToDateString(row[column.property]);
    },
    onChangePage(val) {
      this.currentPage = val;
      console.log(`query 第${val}页`);
      this.onQueryTicket();
    },
    onQueryTicket() {
      if (this.pageType != 1) {
        this.currentPage = 1;
        this.totalPage = 1;
      }
      if (this.courseId <= 0) {
        alertMessage("courseId 不能为0", "warning");
      }
      ticketApi
        .listTicket(this.courseId, this.currentPage)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.totalPage = totalPage;
            this.ticketList = data;
            this.pageType = 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onQueryUsedTicket() {
      if (this.pageType != 2) {
        this.currentPage = 1;
        this.totalPage = 1;
      }
      ticketApi
        .queryUsedTicket(this.usedCourseId, this.currentPage)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.totalPage = totalPage;
            this.ticketList = data;
            this.pageType = 2;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onQueryUsedByPhone() {
      if (this.pageType != 3) {
        this.currentPage = 1;
        this.totalPage = 1;
      }
      ticketApi
        .queryUsedTicketByPhone(this.phone)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.totalPage = totalPage;
            this.ticketList = data;
            this.pageType = 3;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>

</style>
